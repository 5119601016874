#version 300 es
/*
* Preprocessed from './main.frag'
* [Embedded string is compressed]
*/












precision mediump float;
precision mediump sampler3D;


in vec2 v_unitPosition;
in vec2 v_texCoord;

uniform sampler2D u_image;
uniform vec2 u_size;
uniform float u_intensity;

uniform sampler2D u_images[7];
uniform sampler3D u_luts[7];

struct Adjustments {
  float brightness;
  float contrast;
  float saturation;
};
uniform Adjustments u_adjustments;

struct EffectOp {
  int type;
  int image;
  int lut;
  float args[4];
  float intensity;
};
uniform EffectOp u_operations[10];

out vec4 fragColor;

vec4 lookup(vec4 texColor, sampler3D lut, float intensity){
  vec3 cubeSize = vec3(textureSize(lut, 0));

  vec3 transformedColor = texture(lut,(texColor . rgb *(cubeSize - 1.0)+ 0.5)/ cubeSize). rgb;

  vec4 result = mix(texColor, vec4(transformedColor, texColor . a), intensity);
  return result;
}

float vignette(vec2 uv, float radius, float smoothness){
 float diff = radius - distance(uv, vec2(0.5, 0.5));
 return smoothstep(- smoothness, smoothness, diff);
}

float luma_1(vec3 color){
  return dot(color, vec3(0.299, 0.587, 0.114));
}

float luma_1(vec4 color){
  return dot(color . rgb, vec3(0.299, 0.587, 0.114));
}

vec4 brightness_0(vec4 color, float value){
  return vec4(color . rgb *(value + 1.0), color . a);
}

vec4 contrast_0(vec4 color, float value){
    return vec4((color . rgb - 0.5)*(value + 1.0)+ 0.5, color . a);
}

vec4 saturation_0(vec4 color, float value){
    return mix(vec4(vec3(luma_1(color)), color . a), color, 1.0 + value);
}

vec4 adjustColor(vec4 color, float b, float c, float s, float intensity){
    vec4 result = color;

    result = brightness_0(result, b);
    result = contrast_0(result, c);
    result = saturation_0(result, s);

    return mix(color, result, intensity);
}















vec3 mod289_1(vec3 x)
{
  return x - floor(x *(1.0 / 289.0))* 289.0;
}

vec4 mod289_1(vec4 x)
{
  return x - floor(x *(1.0 / 289.0))* 289.0;
}

vec4 permute_1(vec4 x)
{
  return mod289_1(((x * 34.0)+ 1.0)* x);
}

vec4 taylorInvSqrt_1(vec4 r)
{
  return 1.79284291400159 - 0.85373472095314 * r;
}

vec3 fade(vec3 t){
  return t * t * t *(t *(t * 6.0 - 15.0)+ 10.0);
}


float pnoise(vec3 P, vec3 rep)
{
  vec3 Pi0 = mod(floor(P), rep);
  vec3 Pi1 = mod(Pi0 + vec3(1.0), rep);
  Pi0 = mod289_1(Pi0);
  Pi1 = mod289_1(Pi1);
  vec3 Pf0 = fract(P);
  vec3 Pf1 = Pf0 - vec3(1.0);
  vec4 ix = vec4(Pi0 . x, Pi1 . x, Pi0 . x, Pi1 . x);
  vec4 iy = vec4(Pi0 . yy, Pi1 . yy);
  vec4 iz0 = Pi0 . zzzz;
  vec4 iz1 = Pi1 . zzzz;

  vec4 ixy = permute_1(permute_1(ix)+ iy);
  vec4 ixy0 = permute_1(ixy + iz0);
  vec4 ixy1 = permute_1(ixy + iz1);

  vec4 gx0 = ixy0 *(1.0 / 7.0);
  vec4 gy0 = fract(floor(gx0)*(1.0 / 7.0))- 0.5;
  gx0 = fract(gx0);
  vec4 gz0 = vec4(0.5)- abs(gx0)- abs(gy0);
  vec4 sz0 = step(gz0, vec4(0.0));
  gx0 -= sz0 *(step(0.0, gx0)- 0.5);
  gy0 -= sz0 *(step(0.0, gy0)- 0.5);

  vec4 gx1 = ixy1 *(1.0 / 7.0);
  vec4 gy1 = fract(floor(gx1)*(1.0 / 7.0))- 0.5;
  gx1 = fract(gx1);
  vec4 gz1 = vec4(0.5)- abs(gx1)- abs(gy1);
  vec4 sz1 = step(gz1, vec4(0.0));
  gx1 -= sz1 *(step(0.0, gx1)- 0.5);
  gy1 -= sz1 *(step(0.0, gy1)- 0.5);

  vec3 g000 = vec3(gx0 . x, gy0 . x, gz0 . x);
  vec3 g100 = vec3(gx0 . y, gy0 . y, gz0 . y);
  vec3 g010 = vec3(gx0 . z, gy0 . z, gz0 . z);
  vec3 g110 = vec3(gx0 . w, gy0 . w, gz0 . w);
  vec3 g001 = vec3(gx1 . x, gy1 . x, gz1 . x);
  vec3 g101 = vec3(gx1 . y, gy1 . y, gz1 . y);
  vec3 g011 = vec3(gx1 . z, gy1 . z, gz1 . z);
  vec3 g111 = vec3(gx1 . w, gy1 . w, gz1 . w);

  vec4 norm0 = taylorInvSqrt_1(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
  g000 *= norm0 . x;
  g010 *= norm0 . y;
  g100 *= norm0 . z;
  g110 *= norm0 . w;
  vec4 norm1 = taylorInvSqrt_1(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
  g001 *= norm1 . x;
  g011 *= norm1 . y;
  g101 *= norm1 . z;
  g111 *= norm1 . w;

  float n000 = dot(g000, Pf0);
  float n100 = dot(g100, vec3(Pf1 . x, Pf0 . yz));
  float n010 = dot(g010, vec3(Pf0 . x, Pf1 . y, Pf0 . z));
  float n110 = dot(g110, vec3(Pf1 . xy, Pf0 . z));
  float n001 = dot(g001, vec3(Pf0 . xy, Pf1 . z));
  float n101 = dot(g101, vec3(Pf1 . x, Pf0 . y, Pf1 . z));
  float n011 = dot(g011, vec3(Pf0 . x, Pf1 . yz));
  float n111 = dot(g111, Pf1);

  vec3 fade_xyz = fade(Pf0);
  vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz . z);
  vec2 n_yz = mix(n_z . xy, n_z . zw, fade_xyz . y);
  float n_xyz = mix(n_yz . x, n_yz . y, fade_xyz . x);
  return 2.2 * n_xyz;
}












vec3 mod289_0(vec3 x){
  return x - floor(x *(1.0 / 289.0))* 289.0;
}

vec4 mod289_0(vec4 x){
  return x - floor(x *(1.0 / 289.0))* 289.0;
}

vec4 permute_0(vec4 x){
     return mod289_0(((x * 34.0)+ 1.0)* x);
}

vec4 taylorInvSqrt_0(vec4 r)
{
  return 1.79284291400159 - 0.85373472095314 * r;
}

float snoise(vec3 v)
  {
  const vec2 C = vec2(1.0 / 6.0, 1.0 / 3.0);
  const vec4 D = vec4(0.0, 0.5, 1.0, 2.0);


  vec3 i = floor(v + dot(v, C . yyy));
  vec3 x0 = v - i + dot(i, C . xxx);


  vec3 g_0 = step(x0 . yzx, x0 . xyz);
  vec3 l = 1.0 - g_0;
  vec3 i1 = min(g_0 . xyz, l . zxy);
  vec3 i2 = max(g_0 . xyz, l . zxy);





  vec3 x1 = x0 - i1 + C . xxx;
  vec3 x2 = x0 - i2 + C . yyy;
  vec3 x3 = x0 - D . yyy;


  i = mod289_0(i);
  vec4 p = permute_0(permute_0(permute_0(
             i . z + vec4(0.0, i1 . z, i2 . z, 1.0))
           + i . y + vec4(0.0, i1 . y, i2 . y, 1.0))
           + i . x + vec4(0.0, i1 . x, i2 . x, 1.0));



  float n_ = 0.142857142857;
  vec3 ns = n_ * D . wyz - D . xzx;

  vec4 j = p - 49.0 * floor(p * ns . z * ns . z);

  vec4 x_ = floor(j * ns . z);
  vec4 y_ = floor(j - 7.0 * x_);

  vec4 x = x_ * ns . x + ns . yyyy;
  vec4 y = y_ * ns . x + ns . yyyy;
  vec4 h = 1.0 - abs(x)- abs(y);

  vec4 b0 = vec4(x . xy, y . xy);
  vec4 b1 = vec4(x . zw, y . zw);



  vec4 s0 = floor(b0)* 2.0 + 1.0;
  vec4 s1 = floor(b1)* 2.0 + 1.0;
  vec4 sh = - step(h, vec4(0.0));

  vec4 a0 = b0 . xzyw + s0 . xzyw * sh . xxyy;
  vec4 a1 = b1 . xzyw + s1 . xzyw * sh . zzww;

  vec3 p0 = vec3(a0 . xy, h . x);
  vec3 p1 = vec3(a0 . zw, h . y);
  vec3 p2 = vec3(a1 . xy, h . z);
  vec3 p3 = vec3(a1 . zw, h . w);


  vec4 norm = taylorInvSqrt_0(vec4(dot(p0, p0), dot(p1, p1), dot(p2, p2), dot(p3, p3)));
  p0 *= norm . x;
  p1 *= norm . y;
  p2 *= norm . z;
  p3 *= norm . w;


  vec4 m = max(0.6 - vec4(dot(x0, x0), dot(x1, x1), dot(x2, x2), dot(x3, x3)), 0.0);
  m = m * m;
  return 42.0 * dot(m * m, vec4(dot(p0, x0), dot(p1, x1),
                                dot(p2, x2), dot(p3, x3)));
  }

float grain(vec2 texCoord, vec2 resolution, float frame, float multiplier){
    vec2 mult = texCoord * resolution;
    float offset = snoise(vec3(mult / multiplier, frame));
    float n1 = pnoise(vec3(mult, offset), vec3(1.0 / texCoord * resolution, 1.0));
    return n1 / 2.0 + 0.5;
}

float grain(vec2 texCoord, vec2 resolution, float frame){
    return grain(texCoord, resolution, frame, 2.5);
}

float grain(vec2 texCoord, vec2 resolution){
    return grain(texCoord, resolution, 0.0);
}

float luma_0(vec3 color){
  return dot(color, vec3(0.299, 0.587, 0.114));
}

float luma_0(vec4 color){
  return dot(color . rgb, vec3(0.299, 0.587, 0.114));
}

float blendSoftLight(float base, float blend){
 return(blend < 0.5)?(2.0 * base * blend + base * base *(1.0 - 2.0 * blend)):(sqrt(base)*(2.0 * blend - 1.0)+ 2.0 * base *(1.0 - blend));
}

vec3 blendSoftLight(vec3 base, vec3 blend){
 return vec3(blendSoftLight(base . r, blend . r), blendSoftLight(base . g, blend . g), blendSoftLight(base . b, blend . b));
}

vec3 blendSoftLight(vec3 base, vec3 blend, float opacity){
 return(blendSoftLight(base, blend)* opacity + base *(1.0 - opacity));
}


vec3 filmGrain(vec3 backgroundColor, vec2 texCoord, vec2 resolution, float intensity){
    vec3 grainColor = vec3(grain(texCoord, resolution / vec2(2)));



    vec3 color = blendSoftLight(backgroundColor, grainColor);


    float luminance = luma_0(backgroundColor);



    float response = smoothstep(0.05, 0.5, luminance);
    color = mix(color, backgroundColor, pow(response, 2.0));

    return mix(backgroundColor, color, intensity);
}

vec4 sepia(vec4 texColor, float amount){
  mat4 matrix = mat4(
    (0.393 + 0.607 *(1.0 - amount)),(0.769 - 0.769 *(1.0 - amount)),(0.189 - 0.189 *(1.0 - amount)), 0,
    (0.349 - 0.349 *(1.0 - amount)),(0.686 + 0.314 *(1.0 - amount)),(0.168 - 0.168 *(1.0 - amount)), 0,
    (0.272 - 0.272 *(1.0 - amount)),(0.534 - 0.534 *(1.0 - amount)),(0.131 + 0.869 *(1.0 - amount)), 0,
    0, 0, 0, 1
  );

  return texColor * matrix;
}

vec4 hueRotate(vec4 texColor, float angleDeg){
  float rads = radians(angleDeg);

  mat4 a = mat4(vec4(.213, .715, .072, 0),
                vec4(.213, .715, .072, 0),
                vec4(.213, .715, .072, 0),
                vec4(0, 0, 0, 1));
  mat4 b = mat4(vec4(.787, - .715, - .072, 0),
                vec4(- .213, .285, - .072, 0),
                vec4(- .213, - .715, .928, 0),
                vec4(0, 0, 0, 1));
  mat4 c = mat4(vec4(- .213, - .715, .928, 0),
                vec4(.143, .140, - .283, 0),
                vec4(- .787, .715, .072, 0),
                vec4(0, 0, 0, 1));

  mat4 matrix = a +(b * cos(rads))+(c * sin(rads));

  return texColor * matrix;
}

vec4 lookupWithIndex(vec4 color, int lutIndex, float intensity){
  switch(lutIndex){
    case 0 : return lookup(color, u_luts[0], intensity);
    case 1 : return lookup(color, u_luts[1], intensity);
    case 2 : return lookup(color, u_luts[2], intensity);
    case 3 : return lookup(color, u_luts[3], intensity);
    case 4 : return lookup(color, u_luts[4], intensity);
    case 5 : return lookup(color, u_luts[5], intensity);
    default : return lookup(color, u_luts[6], intensity);
  }
}

vec4 applyOperation(EffectOp op, vec4 color, vec2 coord, float effectIntensity){
  float intensity = op . intensity * effectIntensity;

  switch(op . type){
    case 1 : return lookupWithIndex(color, op . lut, intensity);
    case 2 : return vec4(color . rgb * vignette(v_unitPosition, 1.0 - intensity, 0.35), color . a);
    case 3 : return adjustColor(color, op . args[0], op . args[1], op . args[2], intensity);
    case 4 : return vec4(filmGrain(color . rgb, v_texCoord, u_size, intensity), color . a);
    case 5 : return sepia(color, intensity);
    case 6 : return mix(color, hueRotate(color, op . args[0]), intensity);
    default : return color;
  }
}

vec4 applyAdjustments(Adjustments values, vec4 color){
  return adjustColor(color, values . brightness, values . contrast, values . saturation, 1.0);
}

void main(){
  vec4 color = texture(u_image, v_texCoord);

  color = applyAdjustments(u_adjustments, color);

  for(int i = 0;i < 10;i ++){
    color = applyOperation(u_operations[i], color, v_texCoord, u_intensity);
  }

  fragColor = color;
}
